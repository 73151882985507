<template>
  <div class=''>
    <div class="center">
      <div class="list">
        <div class="title">{{detail.OrgName}}</div>
        <div class="flex">
          <span>民宿旅馆: {{detail.HotelName}}</span>
          <span>房间类型: {{detail.TypeName}}</span>
        </div>
        <div class="flex">
          <span>预订时间: {{detail.PreTime}}</span>
        </div>
        <div class="flex">
          <span>旅客姓名: {{detail.Name}}</span>
          <span>房间数量: {{detail.RoomNum}}</span>
        </div>

        <div class="flex">
          <span>手机号: {{detail.Mobile}} </span>
        </div>

        <div class="flex">
          <span>身份证号: {{detail.IdCard}}</span>
        </div>
        <div class="flex">
          <span>预计到店时间: {{ detail.ArriveTime }}</span>
        </div>
        <div class="flex">
          <span>预计入住日期: {{ detail.InDate }}</span>
        </div>
        <div class="flex">
          <span>预计退房日期: {{ detail.LeaveDate }}</span>
        </div>
        <div class="flex"
          v-if="detail.Status == 3">
          <span>确认时间: {{ detail.ConfirmTime }}</span>
        </div>
        <div class="flex"
          v-if="detail.Status == 3">
          <span>确认人: {{ detail.ConfirmMan }}</span>
        </div>

        <div class="flex"
          v-if="detail.Status == 3">
          <span>情况说明: {{ detail.Situation }}</span>
        </div>
        <div class="flex"
          v-if="detail.Status == 3">
          <span>入住时间: {{ detail.InTime }}</span>
        </div>
        <div class="flex"
          v-if="detail.Status == 9">
          <span>取消时间: {{ detail.CancelTime }}</span>
        </div>

        <div class="Status"
          style="background-color: #4cbfec;"
          v-if="detail.Status == 1">待确认</div>
        <div class="Status"
          style="background-color: #e6776f;"
          v-if="detail.Status == 2">待入住</div>
        <div class="Status"
          style="background-color: #02c08f;"
          v-if="detail.Status == 3">已入住</div>
        <div class="Status"
          style="background-color: #d61d10;"
          v-if="detail.Status == 8">已退订</div>
        <div class="Status"
          style="background-color: #d61d10;"
          v-if="detail.Status == 9">已取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { WxGetMyPreHotel } from "@/api/shop";
import { getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      detail: {},
    };
  },
  mounted () {
    this.getJobDetail()
  },

  methods: {
    // 获取详情
    getJobDetail () {
      WxGetMyPreHotel({
        preId: this.$route.params.preId,
        openID: getOpenId(),
      }).then((res) => {
        if (res.data.code == 0) {
          this.detail = res.data.data
        }
      })

    },
  }
}
</script>
<style scoped>
.list {
  position: relative;
  width: 96%;
  margin: 2% 2%;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  box-shadow: 0 0 5px #dadada;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 14px;
  color: #333;
  padding: 10px 20px 15px;
  justify-content: space-between;
}

.list .flex text:nth-child(1) {
  width: 40%;
}

.list .flex text:nth-child(2) {
  width: 60%;
}

.list .Status {
  position: absolute;
  top: 0px;
  right: 10px;
  /* background: #4b8cff; */
  font-size: 16px;
  padding: 5px 0px;
  color: #fff;
  width: 85px;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>